<template>
  <v-fade-transition
    v-if="meta"
    leave-absolute
    group
  >
    <v-icon
      v-if="meta.required && !meta.touched"
      key="required"
      icon="mdi-asterisk"
      size="xs"
    />
    <v-icon
      v-if="(!meta.valid || hasErrors()) && meta.touched"
      key="invalid"
      icon="mdi-exclamation-thick"
      color="error"
    />

    <v-icon
      v-if="meta.dirty && meta.valid && !hasErrors()"
      key="valid"
      icon="mdi-check"
      color="success"
    />
  </v-fade-transition>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MtfFieldIcon',
  // ----------------
  inheritAttrs: true,
  props: {
    meta: { type: Object },
    hasErrors: { type: [Boolean, Function] }
  },
  customOptions: {}
});
</script>
